export const Routes = {
  root: "/",
  categories: {
    detail: "/categories/:categoryKey",
  },
  games: {
    detail: "/games/:gameKey",
    twoZeroFourEight: "/games/2048",
    unblockMe: "/games/unblock-me",
  },
};
