import { ArrowBackIos, RestartAlt } from "@mui/icons-material";
import { Box, Card, IconButton, Stack, Typography } from "@mui/material";
import { useCallback, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useHammer, useKeyDown } from "src/hooks";
import "src/styles/2048.style.css";
import { ArrowKey, GameAction, KeyDirection, SwipeKey } from "src/types";
import { sendMessage } from "src/utils";
import { Board } from "./board";
import { TwoZeroFourEightGame } from "./game";

const reducer = (
  state: { game: TwoZeroFourEightGame },
  action: KeyDirection | GameAction.NEW
) => {
  switch (action) {
    case GameAction.NEW:
      state.game.start();
      break;
    default:
      state.game.hit(action);
      break;
  }
  return { ...state };
};

function TwoZeroFourEight() {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, undefined, () => ({
    game: new TwoZeroFourEightGame(4).start(),
  }));

  const keyCommand = useCallback(
    (key: any) => {
      switch (key) {
        case ArrowKey.ArrowUp:
          dispatch(KeyDirection.UP);
          break;
        case ArrowKey.ArrowDown:
          dispatch(KeyDirection.DOWN);
          break;
        case ArrowKey.ArrowLeft:
          dispatch(KeyDirection.LEFT);
          break;
        case ArrowKey.ArrowRight:
          dispatch(KeyDirection.RIGHT);
          break;
      }
    },
    [dispatch]
  );

  const swipeCommand = useCallback(
    (key: any) => {
      switch (key) {
        case SwipeKey.swipeup:
          dispatch(KeyDirection.UP);
          break;
        case SwipeKey.swipedown:
          dispatch(KeyDirection.DOWN);
          break;
        case SwipeKey.swipeleft:
          dispatch(KeyDirection.LEFT);
          break;
        case SwipeKey.swiperight:
          dispatch(KeyDirection.RIGHT);
          break;
      }
    },
    [dispatch]
  );

  useKeyDown(keyCommand);
  useHammer(swipeCommand);

  return (
    <div className="two-zero-four-eight">
      <div className="nav">
        <div
          onClick={() => {
            navigate(-1);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <Stack direction="row" alignItems="center">
            <Box width={8} />
            <IconButton aria-label="back">
              <ArrowBackIos></ArrowBackIos>
            </IconButton>
            <Typography variant="h5">2048</Typography>
          </Stack>
        </div>
      </div>
      <Stack
        className="game"
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="header"
        >
          <Typography variant="h3">2048</Typography>
          <IconButton
            aria-label="restart"
            id="#new-game-btn"
            onClick={() => {
              sendMessage();
              dispatch(GameAction.NEW);
            }}
          >
            <RestartAlt style={{ fontSize: 32 }} />
          </IconButton>
          <Card className="score-card">
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h5" color="white">
                Score
              </Typography>
              <Typography variant="h4" color="white">
                {state.game.score}
              </Typography>
            </Stack>
          </Card>
        </Stack>
        <Board game={state.game} />
        <Box height={32} />
      </Stack>
    </div>
  );
}

export default TwoZeroFourEight;
