import { KeyDirection } from "src/types";
import { TwoZeroFourEightUtil, getRandomValueInRange } from "src/utils";

export type SquareType = { id: number; value: number };

export class BoardType {
  private size: number = 0;
  private board: SquareType[][] = [];

  constructor(size: number) {
    this.size = size;
    this.reset();
  }

  get(): SquareType[][] {
    return this.board;
  }

  set(board: SquareType[][]) {
    this.board = board;
  }

  get getRows() {
    return this.board;
  }

  get squares(): SquareType[] {
    return Array.prototype.concat([], ...this.board);
  }

  getSquareId = (i: number, j: number) => {
    return i * this.size + j;
  };

  getSquare = (i: number, j: number) => {
    return this.board[i][j];
  };

  setSquare = (i: number, j: number, value: number) => {
    return (this.board[i][j].value = value);
  };

  getEmptySquaresCount = () => {
    return this.squares.filter((s) => s.value === 0).length;
  };

  reset() {
    this.board = new Array(this.size);
    for (var i = 0; i < this.size; i++) {
      this.board[i] = new Array(this.size);
      for (var j = 0; j < this.size; j++) {
        this.board[i][j] = { id: this.getSquareId(i, j), value: 0 };
      }
    }
  }

  transposeFn = <T>(
    f: (first: number, second: number, ...rest: any[]) => T
  ): typeof f => {
    return (first, second, ...rest) => {
      return f(second, first, ...rest);
    };
  };

  backwardsFn = <T>(
    f: (first: number, second: number, ...rest: any[]) => T
  ): typeof f => {
    return (first, second, ...rest) => {
      return f(first, this.size - second - 1, ...rest);
    };
  };

  combineRows = (i: number, keyDirection: KeyDirection) => {
    let changed = false;
    let repeat: boolean;
    let g = this.getSquare;
    let s = this.setSquare;

    if ([KeyDirection.UP, KeyDirection.DOWN].includes(keyDirection)) {
      g = this.transposeFn(g);
      s = this.transposeFn(s);
    }

    if ([KeyDirection.RIGHT, KeyDirection.DOWN].includes(keyDirection)) {
      g = this.backwardsFn(g);
      s = this.backwardsFn(s);
    }

    do {
      repeat = false;
      for (let j = 0; j < this.size - 1; j++) {
        if (g(i, j).value === 0 && g(i, j + 1).value !== 0) {
          s(i, j, g(i, j + 1).value);
          s(i, j + 1, 0);
          repeat = true;
          changed = true;
        }
      }
    } while (repeat);
    return changed;
  };

  canMove = (keyDirection: KeyDirection) => {
    let g = this.getSquare;
    let s = this.setSquare;

    if ([KeyDirection.UP, KeyDirection.DOWN].includes(keyDirection)) {
      g = this.transposeFn(g);
      s = this.transposeFn(s);
    }

    if ([KeyDirection.RIGHT, KeyDirection.DOWN].includes(keyDirection)) {
      g = this.backwardsFn(g);
      s = this.backwardsFn(s);
    }

    let changed = false;
    for (let i = 0; i < this.size; i++) {
      changed = this.combineRows(i, keyDirection) || changed;
      for (let j = 0; j < this.size - 1; j++) {
        const current = g(i, j);
        const next = g(i, j + 1);
        if (
          current.value !== 0 &&
          next.value !== 0 &&
          current.value === next.value
        ) {
          changed = true;
        }
      }
    }
    return changed;
  };

  move = (keyDirection: KeyDirection) => {
    let score = 0;

    let g = this.getSquare;
    let s = this.setSquare;

    if ([KeyDirection.UP, KeyDirection.DOWN].includes(keyDirection)) {
      g = this.transposeFn(g);
      s = this.transposeFn(s);
    }

    if ([KeyDirection.RIGHT, KeyDirection.DOWN].includes(keyDirection)) {
      g = this.backwardsFn(g);
      s = this.backwardsFn(s);
    }

    let changed = false;
    for (let i = 0; i < this.size; i++) {
      changed = this.combineRows(i, keyDirection) || changed;
      for (let j = 0; j < this.size - 1; j++) {
        const current = g(i, j);
        const next = g(i, j + 1);
        if (
          current.value !== 0 &&
          next.value !== 0 &&
          current.value === next.value
        ) {
          const sum = current.value + next.value;
          score += sum;
          s(i, j, sum);
          s(i, j + 1, 0);
          changed = true;
        }
      }
      changed = this.combineRows(i, keyDirection) || changed;
    }
    return { changed, score };
  };

  createSquare = () => {
    let isCreated = false;
    while (!isCreated) {
      let i = getRandomValueInRange(0, this.size - 1);
      let j = getRandomValueInRange(0, this.size - 1);
      const square = this.getSquare(i, j);
      if (square.value === 0) {
        isCreated = true;
        this.setSquare(i, j, TwoZeroFourEightUtil.getRandomValue(2, 4));
      }
    }
  };

  print() {
    console.log(
      this.getRows
        .map((row) =>
          row.map((c) => (c ? c.value + "" : "-").padStart(5, " ")).join("  ")
        )
        .join("\n")
    );
  }
}

export class TwoZeroFourEightGame {
  score = 0;
  board: BoardType = new BoardType(4);

  constructor(public size: number) {
    this.score = 0;
    this.board.reset();
  }

  hit(keyDirection: KeyDirection) {
    let runRes: { changed: boolean; score: number } =
      this.board.move(keyDirection);
    if (runRes.changed) {
      this.score += runRes.score;
      this.board.createSquare();
    }
  }

  isGameOver() {
    let emptySquaresCount: number = this.board.getEmptySquaresCount();
    return (
      emptySquaresCount === 0 &&
      !(
        this.board.canMove(KeyDirection.UP) ||
        this.board.canMove(KeyDirection.LEFT)
      )
    );
  }

  start() {
    this.score = 0;
    this.board.reset();
    this.board.createSquare();
    return this;
  }
}
