import React from "react";
import reportWebVitals from "./reportWebVitals";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import TwoZeroFourEight from "src/pages/2048";
import { Routes } from "./constants";
import "./index.css";
import CategoryDetail from "./pages/categories/detail";
import Dashboard from "./pages/dashboard";
import UnblockMe from "./pages/unblock-me";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: Routes.root,
    element: <Dashboard />,
  },
  {
    path: Routes.categories.detail,
    element: <CategoryDetail />,
  },
  {
    path: Routes.games.twoZeroFourEight,
    element: <TwoZeroFourEight />,
  },
  {
    path: Routes.games.unblockMe,
    element: <UnblockMe />,
  },
]);

root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
