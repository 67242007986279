import { Box, Stack, Theme, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Routes } from "src/constants";

export const GameCard = (props: {
  game: {
    key: string;
    name: string;
    status: string;
  };
}) => {
  const theme: Theme = useTheme();
  const navigate = useNavigate();
  const { game } = props;

  return (
    <Link
      to={Routes.games.detail.replace(":gameKey", game.key)}
      style={{ textDecoration: "none" }}
    >
      <div
        style={{
          margin: 8,
          padding: 8,
          borderRadius: 8,
        }}
      >
        <Stack direction="column" alignItems="flex-start">
          <img
            src={`/images/app-logos/${game.key}.png`}
            style={{
              height: 160,
              objectFit: "contain",
              borderRadius: 8,
            }}
          />
          <Box height={16} />
          <Typography
            style={{
              fontSize: 16,
              fontWeight: "bold",
              color: theme.palette.text.primary,
            }}
          >
            {game.name}
          </Typography>
        </Stack>
      </div>
    </Link>
  );
};
