export function sendMessage() {
  var newGameBtn = document.getElementById("#new-game-btn");
  if (newGameBtn) {
    newGameBtn.onclick = function () {
      try {
        window.JSBridge.showMessageInNative("Clicked new game button");
      } catch (error) {
        console.error(error);
      }
    };
  }
}
