export const ObjectGameCategories = [
  {
    key: "my-games",
    name: "My games",
    status: "disabled",
    games: [],
  },
  {
    key: "recommended-for-you",
    name: "Recommended for you",
    status: "disabled",
    games: [],
  },
  {
    key: "trending-games",
    name: "Trending games",
    status: "enabled",
    games: [
      {
        key: "2048",
        name: "2048",
        status: "enabled",
      },
      {
        key: "unblock-me",
        name: "Unblock Me",
        status: "enabled",
      },
    ],
  },
  {
    key: "puzzle",
    name: "Puzzle",
    status: "enabled",
    games: [
      {
        key: "2048",
        name: "2048",
        status: "enabled",
      },
      {
        key: "unblock-me",
        name: "Unblock Me",
        status: "enabled",
      },
    ],
  },
  {
    key: "drive",
    name: "Drive",
    status: "disabled",
    games: [],
  },
  {
    key: "card",
    name: "Card",
    status: "disabled",
    games: [],
  },
  {
    key: "sport",
    name: "Sport",
    status: "disabled",
    games: [],
  },
];
