import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { GameCard } from "src/components";
import { ObjectGameCategories } from "src/constants";

const CategoryDetail = () => {
  const theme: Theme = useTheme();
  const navigate = useNavigate();
  const { categoryKey } = useParams();
  const category = ObjectGameCategories.find((c) => c.key === categoryKey);

  return (
    <div className="main">
      <div className="nav">
        <div
          onClick={() => {
            navigate(-1);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <Stack direction="row" alignItems="center">
            <Box width={8} />
            <IconButton aria-label="back">
              <ArrowBackIos></ArrowBackIos>
            </IconButton>
            <Typography variant="h5">{category?.name}</Typography>
          </Stack>
        </div>
      </div>
      <Grid container spacing={2}>
        {category?.games.map((game) => (
          <Grid item xs={6} md={2}>
            <GameCard game={game} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CategoryDetail;
