import Hammer from "hammerjs";
import { useEffect } from "react";
import { SwipeKey, TouchKey } from "src/types";

export function useHammer(handler: (gesture: string) => void) {
  useEffect(() => {
    function pd(e: Event) {
      e.preventDefault();
    }
    document.body.addEventListener(TouchKey.touchmove, pd);

    const hammer = new Hammer(document.body);
    hammer.get("swipe").set({
      direction: Hammer.DIRECTION_ALL,
      threshold: 0,
      velocity: 0.1,
    });
    hammer.on(
      `${SwipeKey.swipeleft} ${SwipeKey.swiperight} ${SwipeKey.swipeup} ${SwipeKey.swipedown}`,
      (ev) => {
        handler(ev.type);
      }
    );

    return () => {
      hammer.destroy();
      document.body.removeEventListener(TouchKey.touchmove, pd);
    };
  }, [handler]);
}
