export enum SwipeKey {
  swipeup = "swipeup",
  swipedown = "swipedown",
  swipeleft = "swipeleft",
  swiperight = "swiperight",
}

export enum ArrowKey {
  ArrowUp = "ArrowUp",
  ArrowDown = "ArrowDown",
  ArrowLeft = "ArrowLeft",
  ArrowRight = "ArrowRight",
}

export enum KeyDirection {
  UP = "UP",
  DOWN = "DOWN",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum TouchKey {
  touchmove = "touchmove",
}
