import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  colors,
} from "@mui/material";
import Hammer from "hammerjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { UnblockMeBlock } from "src/models";
import { Block } from "./block";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Pause,
  Replay,
  RestartAlt,
  TipsAndUpdates,
  Undo,
} from "@mui/icons-material";

interface Level {
  level: number;
  blocks: UnblockMeBlock[];
}

const BOARD_SIZE = 6;
const DOOR_POSITION = { x: 6, y: 2 };

const GameBoard = () => {
  const [levelsTotal, setLevelsTotal] = useState(0);
  const [levels, setLevels] = useState<Level[]>([]);
  const [levelIndex, setLevelIndex] = useState(0);
  const [level, setLevel] = useState<Level>({ level: 1, blocks: [] });
  const [moves, setMoves] = useState(0);
  const [prevBlocks, setPrevBlocks] = useState<UnblockMeBlock[]>([]);
  const [blocks, setBlocks] = useState<UnblockMeBlock[]>([]);
  const [activeBlock, setActiveBlock] = useState<UnblockMeBlock | null>(null);
  const [offset, setOffset] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const boardRef = useRef<HTMLDivElement>(null);
  const [isVertical, setIsVertical] = useState(
    window.innerHeight > window.innerWidth
  );
  const [showGameWinDialog, setShowGameWinDialog] = useState(false);

  const calcBlocks = (blocks: UnblockMeBlock[]) => {
    var sum = 0;
    blocks.forEach((b) => {
      sum += b.id * (b.x * b.width + b.y * b.height);
    });
    return sum;
  };

  const [boardWidth, setBoardWidth] = useState(
    isVertical ? window.innerWidth * 0.9 : window.innerHeight * 0.9
  );

  const [gridSize, setGridSize] = useState(boardWidth / BOARD_SIZE);

  const isGameWin = useCallback((updatedBlocks: UnblockMeBlock[]) => {
    const mainBlock = updatedBlocks.find((block) => block.id === 1);
    if (
      mainBlock &&
      mainBlock.x + mainBlock.width === DOOR_POSITION.x &&
      mainBlock.y === DOOR_POSITION.y
    ) {
      console.log("You win!");
      setShowGameWinDialog(true);
    }
  }, []);

  const handleTouchStart = (block: UnblockMeBlock, e: React.TouchEvent) => {
    try {
      setPrevBlocks(blocks);
      setActiveBlock(block);
      const touch = e.touches[0];
      if (!touch) return;
      const boardRect = boardRef.current!.getBoundingClientRect();
      setOffset({
        x: touch.clientX - (boardRect.left + block.x * gridSize),
        y: touch.clientY - (boardRect.top + block.y * gridSize),
      });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLevels = async () => {
    try {
      const response = await fetch("/assets/json/unblock-me-levels.json");
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const initData = async () => {
    const fetchData: {
      total: number;
      list: { level: number; blocks: UnblockMeBlock[] }[];
    } = await fetchLevels();
    const newTotal = fetchData.total;
    const newLevels = fetchData.list;
    setLevelsTotal(newTotal);
    setLevels(newLevels);

    // init level
    const levelIndex = 0;
    const newLevel = newLevels[levelIndex];
    console.log("newLevel", newLevel);
    if (!newLevel) return;
    setLevelIndex(levelIndex);
    setLevel(newLevel);
    setBlocks(newLevel.blocks);
  };

  const handleRestart = () => {
    setShowGameWinDialog(false);
    const newLevel = levels[levelIndex];
    if (!newLevel) return;
    setMoves(0);
    setLevel(level);
    setBlocks(newLevel.blocks);
    setPrevBlocks(newLevel.blocks);
  };

  const handlePreviousLevel = () => {
    setShowGameWinDialog(false);
    const newLevel = levels[levelIndex - 1];
    if (!newLevel) return;
    setMoves(0);
    setLevelIndex(levelIndex - 1);
    setLevel(newLevel);
    setBlocks(newLevel.blocks);
    setPrevBlocks(newLevel.blocks);
  };

  const handleNextLevel = () => {
    setShowGameWinDialog(false);
    const newLevel = levels[levelIndex + 1];
    if (!newLevel) return;
    setMoves(0);
    setLevelIndex(levelIndex + 1);
    setLevel(newLevel);
    setBlocks(newLevel.blocks);
    setPrevBlocks(newLevel.blocks);
  };

  const handlePause = () => {
    setShowGameWinDialog(false);
  };

  useEffect(() => {
    initData();
    const handleResize = () => {
      const isVertical = window.innerHeight > window.innerWidth;
      setIsVertical(isVertical);
      const newBoardWidth = isVertical
        ? window.innerWidth * 0.9
        : window.innerHeight * 0.9;
      setBoardWidth(newBoardWidth);
      setGridSize(newBoardWidth / BOARD_SIZE);
    };

    // Initial width calculation
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (boardRef.current) {
      const hammer = new Hammer(boardRef.current);

      hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL });

      hammer.on("panstart", (e: any) => {
        if (activeBlock) {
          handleTouchStart(activeBlock, e);
        }
      });

      hammer.on("panmove", (e) => {
        if (activeBlock) {
          const boardRect = boardRef.current!.getBoundingClientRect();
          const newX = e.center.x - boardRect.left - offset.x;
          const newY = e.center.y - boardRect.top - offset.y;

          // Update block position
          setActiveBlock((prev) =>
            prev ? { ...prev, x: newX / gridSize, y: newY / gridSize } : null
          );
        }
      });

      hammer.on("panend", () => {
        setActiveBlock(null);
      });

      return () => {
        hammer.off("panstart");
        hammer.off("panmove");
        hammer.off("panend");
      };
    }
  }, [activeBlock, levelIndex, level, blocks, offset]);

  const handleMouseDown = (block: UnblockMeBlock, e: React.MouseEvent) => {
    setPrevBlocks(blocks);
    setActiveBlock(block);
    const boardRect = boardRef.current!.getBoundingClientRect();
    setOffset({
      x: e.clientX - (boardRect.left + block.x * gridSize),
      y: e.clientY - (boardRect.top + block.y * gridSize),
    });
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    try {
      if (activeBlock) {
        handleMove(e);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    try {
      if (activeBlock) {
        const touch = e.touches[0];
        if (!touch) return;
        handleMove(touch);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleMove = (element: React.Touch | React.MouseEvent) => {
    try {
      if (activeBlock) {
        const boardRect = boardRef.current!.getBoundingClientRect();
        let newX = activeBlock.horizontal
          ? Math.round((element.clientX - offset.x - boardRect.left) / gridSize)
          : activeBlock.x;
        let newY = !activeBlock.horizontal
          ? Math.round((element.clientY - offset.y - boardRect.top) / gridSize)
          : activeBlock.y;

        // Boundary checks
        newX = Math.max(0, Math.min(newX, BOARD_SIZE - activeBlock.width));
        newY = Math.max(0, Math.min(newY, BOARD_SIZE - activeBlock.height));

        // Collision checks
        const collision = blocks.some(
          (block) =>
            block.id !== activeBlock.id &&
            newX < block.x + block.width &&
            newX + activeBlock.width > block.x &&
            newY < block.y + block.height &&
            newY + activeBlock.height > block.y
        );

        if (!collision) {
          const updatedBlocks = blocks.map((block) =>
            block.id === activeBlock.id ? { ...block, x: newX, y: newY } : block
          );
          setBlocks(updatedBlocks);
          isGameWin(updatedBlocks);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleMouseUp = () => {
    if (calcBlocks(prevBlocks) != calcBlocks(blocks)) {
      setMoves(moves + 1);
    }
    setActiveBlock(null);
  };

  return (
    <>
      <Box
        style={{
          width: window.innerWidth,
          height: window.innerHeight,
          backgroundImage: `url("/images/textures/gradient-grunge-wall.jpg")`,
          objectFit: "fill",
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{
              width: boardWidth + 24,
              marginLeft: -16,
              marginTop: (window.innerHeight - boardWidth - 256) / 2,
            }}
          >
            <Grid item xs={7}>
              <Box
                style={{
                  borderRadius: 8,
                  padding: 8,
                  backgroundColor: colors.brown[300],
                  color: colors.amber[50],
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {levelIndex > 0 ? (
                    <IconButton
                      aria-label="back"
                      size="large"
                      onClick={handlePreviousLevel}
                    >
                      <ArrowBackIos style={{ fontSize: 32 }}></ArrowBackIos>
                    </IconButton>
                  ) : (
                    <Box width={56}></Box>
                  )}
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6">Level</Typography>
                    <Typography variant="h3">{level.level}</Typography>
                  </Stack>
                  {levelIndex < levelsTotal - 1 ? (
                    <IconButton
                      aria-label="next"
                      size="large"
                      onClick={handleNextLevel}
                    >
                      <ArrowForwardIos
                        style={{ fontSize: 32 }}
                      ></ArrowForwardIos>
                    </IconButton>
                  ) : (
                    <Box width={56}></Box>
                  )}
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box
                style={{
                  borderRadius: 8,
                  padding: 8,
                  backgroundColor: colors.brown[300],
                  color: colors.amber[50],
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                }}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h6">Moves</Typography>
                  <Typography variant="h3">{moves}</Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                style={{
                  position: "relative",
                }}
              >
                <Box
                  ref={boardRef}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onTouchMove={handleTouchMove}
                  style={{
                    width: boardWidth,
                    height: boardWidth,
                    backgroundColor: "#8f8585",
                    display: "grid",
                    gridTemplateColumns: "repeat(6, 25)",
                    gridTemplateRows: "repeat(6, 25)",
                    overflow: "hidden",
                    margin: "auto",
                    borderRadius: 8,
                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                    border: "4px solid #8f8585",
                  }}
                >
                  {blocks.map((block) => (
                    <Block
                      key={block.id}
                      block={block}
                      gridSize={gridSize}
                      onMouseDown={handleMouseDown}
                      onTouchStart={handleTouchStart}
                      onTouchEnd={handleMouseUp}
                    />
                  ))}
                  <div
                    style={{
                      position: "absolute",
                      backgroundImage: `url("/images/textures/brick-renovated-closeup.jpg")`,
                      transform: `translate(${
                        DOOR_POSITION.x * gridSize - gridSize * 0.06
                      }px, ${DOOR_POSITION.y * gridSize}px)`,
                      width: gridSize * 0.1,
                      height: gridSize,
                      borderRadius: "8px 0 0 8px",
                    }}
                  />
                </Box>
                {showGameWinDialog && (
                  <Box
                    style={{
                      zIndex: 100,
                      position: "absolute",
                      top: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "grid",
                      gridTemplateColumns: "repeat(6, 25)",
                      gridTemplateRows: "repeat(6, 25)",
                      overflow: "hidden",
                      margin: "auto",
                      borderRadius: 8,
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                      border: "4px solid #8f8585",
                    }}
                  >
                    <Box
                      style={{
                        top: boardWidth / 4,
                        left: boardWidth / 4,
                        width: boardWidth / 2,
                        height: boardWidth / 2,
                        backgroundColor: "#8f8585",
                        display: "grid",
                        gridTemplateColumns: "repeat(6, 25)",
                        gridTemplateRows: "repeat(6, 25)",
                        overflow: "hidden",
                        margin: "auto",
                        borderRadius: 8,
                        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Typography variant="h5">Resolved</Typography>
                        {levelIndex === levelsTotal - 1 ? (
                          <Button
                            variant="contained"
                            style={{
                              backgroundImage: `url("/images/textures/red-sandpaper.jpg")`,
                              borderRadius: 8,
                            }}
                            onClick={handleRestart}
                          >
                            Restart
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            style={{
                              backgroundImage: `url("/images/textures/red-sandpaper.jpg")`,
                              borderRadius: 8,
                            }}
                            onClick={handleNextLevel}
                          >
                            Next
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width={boardWidth}
              marginLeft={2}
              marginTop={2}
            >
              <Button
                variant="contained"
                style={{
                  backgroundImage: `url("/images/textures/brick-renovated-closeup.jpg")`,
                  display: "none",
                }}
                onClick={handlePause}
              >
                <Pause style={{ fontSize: 32 }} />
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundImage: `url("/images/textures/brick-renovated-closeup.jpg")`,
                  display: "none",
                }}
                onClick={() => {}}
              >
                <TipsAndUpdates style={{ fontSize: 32 }} />
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundImage: `url("/images/textures/brick-renovated-closeup.jpg")`,
                  display: "none",
                }}
                onClick={() => {}}
              >
                <Replay style={{ fontSize: 32 }} />
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundImage: `url("/images/textures/brick-renovated-closeup.jpg")`,
                }}
                onClick={handleRestart}
              >
                <RestartAlt style={{ fontSize: 32 }} />
              </Button>
            </Stack>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default GameBoard;
