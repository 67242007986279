import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GameCard } from "src/components";
import { ObjectGameCategories, Routes } from "src/constants";

function Dashboard() {
  return (
    <div className="dashboard" style={{ maxWidth: 1024, margin: 24 }}>
      <Stack direction="column" spacing={2}>
        <Typography variant="h3">Game Center</Typography>
        <Typography variant="h6">
          All your games in one place, you can bookmarks, share to your friends
          and more.
        </Typography>

        <Box height={32}></Box>

        {ObjectGameCategories.filter((c) => c.status === "enabled").map((c) => (
          <>
            <GameCategory category={c} />
            <Box height={0}></Box>
          </>
        ))}
      </Stack>
    </div>
  );
}

const GameCategory = (props: {
  category: {
    key: string;
    name: string;
    status: string;
    games: {
      key: string;
      name: string;
      status: string;
    }[];
  };
}) => {
  const theme: Theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card style={{ borderRadius: 8, padding: 8 }}>
      <Stack direction="column">
        <div
          onClick={() => {
            navigate(
              Routes.categories.detail.replace(
                ":categoryKey",
                props.category.key
              )
            );
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" style={{ marginLeft: 8 }}>
              {props.category.name}
            </Typography>
            <IconButton aria-label="back">
              <ArrowForwardIos></ArrowForwardIos>
            </IconButton>
          </Stack>
        </div>
        <Stack
          direction="row"
          style={{
            overflowX: "auto",
            scrollbarWidth: "none",
          }}
        >
          {props.category.games.map((game) => (
            <GameCard game={game} />
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

export default Dashboard;
