import { animated, useSpring } from "@react-spring/web";
import { useEffect, useState } from "react";
import { UnblockMeBlock } from "src/models";

interface BlockProps {
  block: UnblockMeBlock;
  gridSize: number;
  onMouseDown: (block: UnblockMeBlock, e: React.MouseEvent) => void;
  onTouchStart: (block: UnblockMeBlock, e: React.TouchEvent) => void;
  onTouchEnd: (block: UnblockMeBlock, e: React.TouchEvent) => void;
}

export const Block = (props: BlockProps) => {
  const { x, y, width, height, color } = props.block;
  const [gridSize, setGridSize] = useState(props.gridSize);

  useEffect(() => {
    setGridSize(props.gridSize);
    console.log(props.gridSize);
  }, [props.gridSize]);

  var backgroundUrl = "/images/textures/old-white-concrete-wall.jpg";
  if (color === "red") backgroundUrl = "/images/textures/red-sandpaper.jpg";
  else if (props.block.horizontal === true) {
    backgroundUrl = "/images/textures/rough-cement-wall.jpg";
  }

  const [springProps, setSpring] = useSpring(() => ({
    transform: `translate(${x * gridSize}px, ${y * gridSize}px)`,
  }));

  useEffect(() => {
    setSpring({
      transform: `translate(${x * gridSize}px, ${y * gridSize}px)`,
    });
  }, [x, y, setSpring, gridSize]);

  const style = {};

  return (
    <animated.div
      style={{
        width: width * gridSize - gridSize * 0.08,
        height: height * gridSize - gridSize * 0.08,
        backgroundImage: `url(${backgroundUrl})`,
        position: "absolute",
        cursor: "pointer",
        willChange: "transform",
        borderRadius: 8,
        boxShadow: "0 0 8px rgba(0, 0, 0, 0.5)",
        boxSizing: "border-box",
        overflow: "hidden",
        margin: 2,
        ...springProps,
      }}
      onMouseDown={(e) => props.onMouseDown(props.block, e)}
      onTouchStart={(e) => props.onTouchStart(props.block, e)}
      onTouchEnd={(e) => props.onTouchEnd(props.block, e)}
    />
  );
};
