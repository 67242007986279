import { Box, Card, Stack, Typography } from "@mui/material";
import { animated, config, useSpring } from "@react-spring/web";
import { SquareType, TwoZeroFourEightGame } from "./game";

const Square = ({
  square,
  x,
  y,
}: {
  square: SquareType;
  x: number;
  y: number;
}) => {
  const props = useSpring({
    from: {
      zIndex: 200,
      opacity: 0.3,
      transform: `translateX(${19 * x}vmin) translateY(${
        19 * y
      }vmin) scale(2) `,
      boxShadow: `0 1vmin 1vmin rgba(0,0,0,.1)`,
    },
    to: {
      zIndex: 100,
      opacity: 1,
      transform: `translateX(${19 * x}vmin) translateY(${19 * y}vmin) scale(1)`,
      boxShadow: `0 0.2vmin 0.5vmin rgba(0,0,0,.4)`,
    },
    config: { ...config.stiff, mass: 0.7, duration: 300 },
  });
  return (
    <>
      {square.value !== 0 && (
        <animated.div
          style={{
            position: "absolute",
            ...props,
          }}
          className={`square square-${square && square.value}`}
        >
          {square && square.value}
        </animated.div>
      )}

      <div
        style={{
          zIndex: 0,
          position: "absolute",
          transform: `scale(1) translateX(${19 * x}vmin) translateY(${
            19 * y
          }vmin)`,
        }}
        className="square"
      />
    </>
  );
};

export const Board = ({ game }: { game: TwoZeroFourEightGame }) => {
  return (
    <>
      <Card className="game-board">
        {game.board.squares.map((square, i) => (
          <Square
            key={square.id}
            square={square}
            x={i % game.size}
            y={Math.floor(i / game.size)}
          />
        ))}
        {game.isGameOver() && (
          <Card className="game-over">
            <Stack direction="column" alignItems="center" spacing={2}>
              <Typography variant="h1">Game over!</Typography>
              <Typography variant="h2">Score: {game.score}</Typography>
            </Stack>
          </Card>
        )}
      </Card>
    </>
  );
};
